import React from 'react';

import { useForm } from "react-hook-form";
import { Config } from '../../config';

import { HandleResponse } from '../../handleResponse';
import { AuthenticationService } from '../../authenticationService';

import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
  } from "react-bootstrap";

  function registerNewUser(email, password)
  {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password })
      };

      return fetch(Config.apiUrl + "/register", requestOptions)
              .then(response => HandleResponse(response));
  }

export default function RegisterForm() {
    const { register, handleSubmit, /*watch,*/ getValues, setError, formState: { errors } } = useForm();
    const navigate  = useNavigate();

    const onSubmit = (data) => 
    {
        registerNewUser(data.emailRegister, data.passwordRegister)
            .then(
                user => 
                {
                    AuthenticationService.login(data.emailRegister, data.passwordRegister)
                      .then(() => navigate("/UserArea" , { replace: true }));
                },
                error => 
                {
                  setError("passwordrepeat", { message: error + " Please login." });
                }
            );
    }
  
    // console.log(watch("example")); // watch input value by passing the name of it
  
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label htmlFor="emailRegister">Email:</Form.Label><br/>
        <Form.Control
          id="emailRegister"
          {...register("emailRegister", {
            required: "Email is required",
            pattern: {
              value: /\S+@\S+\.\S+/, // email or admin
              message: "Please enter a valid email address"
            }
          })}
          type="text"
          autoComplete="on"
        />
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br/>

        <Form.Label htmlFor="passwordRegister">Password:</Form.Label><br/>
        <Form.Control
          id="passwordRegister"
          {...register("passwordRegister", {
            required: "required",
            minLength: {
              value: 4,
              message: "Please enter a valid password"
            }
          })}
          type="password"
          autoComplete="on"
        />
        {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}<br/>

        <Form.Label htmlFor="passwordrepeat">Repeat Password:</Form.Label><br/>
        <Form.Control
          id="passwordrepeat"
          {...register("passwordrepeat", {
            validate: value => { if (value === getValues("passwordRegister")) return true; else return "Password does not match" },
            required: "required",
            minLength: {
              value: 4,
              message: "Please enter a valid password"
            }
          })}
          type="password"
          autoComplete="on"
        />
        {errors.passwordrepeat && <Form.Text className="text-danger">{errors.passwordrepeat.message}</Form.Text>}<br/>
        <Button variant="primary" type="submit">REGISTER</Button>
      </Form>
    );
  }