import React, { useEffect, useMemo, useState } from "react"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import TableContainer from "../Table/TableContainer"
import { saveAs } from 'file-saver';

import { Config } from '../../config';
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form";

import {
    Form,
    Button,
    Container,
    Col,
    Row,
    Table
} from "react-bootstrap";

async function fetchPersonAsync(id) {
    const response = await fetch(Config.apiUrl + "/person/" + id,
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

async function fetchOrdersAsync(id) {
    const response = await fetch(Config.apiUrl + `/order/byPerson/${id}`,
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

async function fetchOrderDevicesAsync(orderId) {
    const response = await fetch(Config.apiUrl + `/orderDevice/byOrder/${orderId}`,
        {
            method: 'GET',
            headers: AuthHeader()
        })

    return await HandleResponse(response);
}

async function savePerson(person) {
    const jsonString = JSON.stringify(person);
    await fetch(Config.apiUrl + "/person/",
        {
            method: "POST",
            headers: AuthHeader(),
            body: jsonString
        });
}


function newOrder(order) {
    const jsonString = JSON.stringify(order);
    fetch(Config.apiUrl + "/order/",
        {
            method: "POST",
            headers: AuthHeader(),
            body: jsonString
        }).then((response) => HandleResponse(response)).then(() => window.location.reload());
}

function transferLicense(licenseSelection, newOwnerEmail, newOwnerName) {
    Object.keys(licenseSelection).forEach(e => {
        if (licenseSelection[Number(e)]) {
            let orderTransfer = { id: e, newOwnerEmail: newOwnerEmail, newOwnerName: newOwnerName };

            console.log("order to transfer: " + e + " " + newOwnerEmail + " " + newOwnerName);
            fetch(Config.apiUrl + "/order/transfer",
                {
                    method: "POST",
                    headers: AuthHeader(),
                    body: JSON.stringify(orderTransfer)
                })
                .then((response) => HandleResponse(response)).then(() => window.location.reload())
                .catch(error => console.log("Error: (" + error + ")"));
        }
    }
    );
}

async function fetchProductsAsync() {
    const response = await fetch(Config.apiUrl + `/product/`);
    return await HandleResponse(response);
}

function getProductName(productData, id) {
    for (let i = 0; i < productData.length; i++) {
        if (productData[i].id === id) {
            return productData[i].name;
        }
    }

    return "unkown";
}

function refreshSerialKeys(person) {
    const jsonString = JSON.stringify(person);
    fetch(Config.apiUrl + "/order/refreshkeys/",
        {
            method: "POST",
            headers: AuthHeader(),
            body: jsonString
        })
        .then(HandleResponse)
        .catch(error => alert("Error: (" + error + ")"));
}

async function deleteDeviceEvent(deviceOrderId) {
    const response = await fetch(Config.apiUrl + '/orderdevice/' + deviceOrderId,
        {
            method: 'DELETE',
            headers: AuthHeader()
        });

    await HandleResponse(response);
    window.location.reload();
}

export default function AdminPerson() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { register: registerTransfer, handleSubmit: handleSubmitTransfer, formState: { errors: errorsTransfer } } = useForm();
    const { register: registerNewOrder, handleSubmit: handleSubmitNewOrder } = useForm();
    const [selectedRow, setSelectedRow] = useState(null);

    const params = useParams();
    const [productData, setProductData] = useState([]);
    const [personData, setPersonData] = useState([]);
    const [licenseData, setLicenseData] = useState([]);
    const [licenseSelection /*, setLicenseSelection*/] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => { // component did mount replacement
        const doFetch = async () => {
            console.log("useEffect called");

            const products = await fetchProductsAsync().catch(error => console.log("Error: (" + error + ")"));
            await setProductData(products);

            const person = await fetchPersonAsync(String(params.id)).catch(error => console.log("Error: (" + error + ")"));
            await setPersonData(person);

            const licenses = await fetchOrdersAsync(String(params.id)).catch(error => console.log("Error: (" + error + ")"));

            for (let i = 0; i < licenses.length; i++) {
                licenses[i].orderDevices = await fetchOrderDevicesAsync(String(licenses[i].id)).catch(error => console.log("Error: (" + error + ")"));
                if (!Array.isArray(licenses[i].orderDevices)) // server responses with hello string when url not exists
                {
                    licenses[i].orderDevices = [];
                }
            }

            // add product name row
            if (products !== undefined) {
                for (let i = 0; i < licenses.length; i++) {
                    licenses[i].productName = getProductName(products, licenses[i].productId);
                }
            }

            await setLicenseData(licenses);

            setValue("email", person.email);
            setValue("name", person.name);
            setValue("country", person.country);

            setLoading(false);
        }

        doFetch();
        // following line disables warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // we want an empty dependency array to avoid multiple calls to useEffect

    console.log("render " + loading);

    const licenseColumns = useMemo
        (
            () => [
                {
                    Header: " ",
                    Cell: props => (<input type="checkbox" onChange=
                        {
                            (event) => { licenseSelection[props.row.values.id] = event.currentTarget.checked }
                        } />),
                    minWidth: 30,
                    width: 80,
                    maxWidth: 80
                },
                {
                    Header: "ID",
                    accessor: "id",
                    Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>,
                    width: 100
                },
                {
                    Header: "ProductName",
                    accessor: "productName",
                    minWidth: 200,
                    width: 600,
                    maxWidth: 600
                },
                {
                    Header: "CreationDate",
                    accessor: "creationDate",
                    minWidth: 200,
                    width: 420,
                    maxWidth: 420
                },
                {
                    Header: "  ",
                    Cell: (props) => <Button variant="secondary" onClick=
                        {
                            () => {
                                if (props.row.id === props.row.selectedRowId) {
                                    props.row.selectedRowId = null;
                                    setSelectedRow(null);
                                }
                                else {
                                    setSelectedRow(props.row);
                                    props.row.selectedRowId = props.row.id;
                                    props.row.detailComponent =
                                        <div key={props.row.id}>
                                            <div style={{ paddingLeft: "40px" }}>
                                                <h5>Key 1024</h5>
                                                <pre>
                                                    {props.row.original.serialNew}
                                                </pre>
                                                <h5>Key 2048</h5>
                                                <pre>
                                                    {props.row.original.serial2048}
                                                </pre>
                                                <h5>Registered Devices</h5>
                                                {
                                                    (props.row.original.orderDevices !== undefined && props.row.original.orderDevices.length > 0) &&
                                                    (
                                                        <div className="Container">
                                                            <Table className="simpletableDeviceOrder">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="th" scope="col">Device Name</th>
                                                                        <th className="th" scope="col">Device Id Hash</th>
                                                                        <th className="th" style={{ textAlign: "right" }} scope="col">Activation Date</th>
                                                                        <th className="th" scope="col"></th>
                                                                        <th className="th" scope="col"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        props.row.original.orderDevices.map(d =>
                                                                            <tr key={d.id}>
                                                                                <td><b>{d.deviceName}</b></td>
                                                                                <td><b>{d.deviceIdHash}</b></td>
                                                                                <td style={{ textAlign: "right" }}>{d.creationDate}</td>

                                                                                <td style={{ textAlign: "right" }}>
                                                                                    <button className="btn btn-secondary" onClick={
                                                                                        (e) => saveAs(new Blob([d.deviceIdHashEncrypted], { type: "text/plain;charset=utf-8" }), props.row.original.name + "_OfflineKey.txt")
                                                                                    }>Download offline Key</button>
                                                                                </td>

                                                                                <td style={{ textAlign: "right" }}>
                                                                                    <button className="btn btn-secondary" onClick={() => deleteDeviceEvent(d.id)}>Delete</button></td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div style={{ margin: "-10px", paddingTop: "20px", color: "#FFFFFF22", borderBottom: "solid" }}></div>
                                        </div>
                                }
                            }
                        }
                    >DETAIL</Button>,
                    minWidth: 100,
                    width: 100,
                    maxWidth: 100,
                    isResizing: false
                },
                {
                    Header: "    ",
                    minWidth: 20,
                    width: 20,
                    maxWidth: 20,
                    isResizing: false
                },
            ],
            [licenseSelection, setSelectedRow]
        )

    function renderData() {
        return (
            <Container>
                <div className="newstitle">
                    <div className="well">
                        <h5>Person data:</h5>
                    </div>
                </div>
                <div className="well">
                    <Form key={1} onSubmit={handleSubmit(() => savePerson(personData))}>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Email" id="email"
                                    {...register("email", { required: "Email is required", })} type="text" onChange={(e) => setPersonData({ ...personData, email: e.target.value })} />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br />
                            </Col>

                            <Col>
                                <Form.Control placeholder="Name" id="name" {...register("name")} type="text" onChange={(e) => setPersonData({ ...personData, name: e.target.value })} /><br />
                            </Col>

                            <Col>
                                <Form.Control placeholder="Country" id="country" {...register("country")} type="text" onChange={(e) => setPersonData({ ...personData, country: e.target.value })} /><br />
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <Button variant="primary" type="submit">SAVE</Button>
                            </Col>
                            <Col>
                                <Button onClick={() => refreshSerialKeys(personData)}>Refresh Serial Keys</Button>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <div>&nbsp;</div>
                    </Form>

                    <h5>New order/license:</h5>
                    <Form key={3} onSubmit={handleSubmitNewOrder((data) => newOrder({ id: 0, productId: data.productId, personId: personData.id, serial: "", creationDate: "2000-01-01T15:06:46" }))}>
                        <Row>
                            <Col>
                                <Form.Control as="select" id="productId" title="Select a product" {...registerNewOrder("productId")}>
                                    <option value="" defaultValue hidden>Choose product</option>
                                    {
                                        productData.map(n => <option value={n.id} key={n.id}>{n.name}</option>)
                                    }
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button variant="primary" type="submit">NEW ORDER</Button>
                            </Col>
                        </Row>
                    </Form>

                    <div>&nbsp;</div>

                    <h5>Transfer license:</h5>
                    <Form key={2} onSubmit={handleSubmitTransfer((data) => transferLicense(licenseSelection, data.newOwnerEmail, data.newOwnerName))}>
                        <Row>
                            <Col>
                                <Form.Control placeholder="New owners email" id="newOwnerEmail" {...registerTransfer("newOwnerEmail", { required: "Owner email is required" })} type="text" />
                                {errorsTransfer.newOwnerEmail && <Form.Text className="text-danger">{errorsTransfer.newOwnerEmail.message}</Form.Text>}<br />
                            </Col>

                            <Col>
                                <Form.Control placeholder="New owners full name" id="newOwnerName" {...registerTransfer("newOwnerName")} type="text" /><br />
                            </Col>

                            <Col>
                                <Button variant="primary" type="submit">TRANSFER LICENSE</Button>
                            </Col>
                        </Row>
                    </Form>

                    <h5>Licenses:</h5>
                    <TableContainer
                        columns={licenseColumns}
                        data={licenseData}
                        selectedRowDetail={selectedRow}
                    />

                    <div>&nbsp;</div>
                </div>
            </Container>
        );
    }

    let contents = <div className="container"><div className='loader' key="loader"></div></div>;
    if (!loading) {
        contents = renderData();
    }

    return contents;
}