import React, { useState, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthenticationService } from '../../authenticationService';
import { saveAs } from 'file-saver';

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import ChangePasswordForm from './ChangePasswordForm';
import OfflineRegistrationForm from './OfflineRegistrationForm';

import {
    Table
} from "react-bootstrap";

async function fetchProductInfos() {
    const response = await fetch(Config.apiUrl + '/userinfo/products',
        {
            method: 'GET',
            headers: AuthHeader()
        })
    return await HandleResponse(response);
}

export default function UserAreaView() {
    const navigate = useNavigate();
    const location = useLocation();

    const [currentUser, setCurrentUser] = useState(AuthenticationService.currentUserValue);
    const [loading, setLoading] = useState(true);
    const [loyaltyCouponActive, setLoyaltyCouponActive] = useState(false);
    const [productInfos, setProductInfos] = useState([]);

    useEffect(() => {
        console.log("useEffect called");
        setCurrentUser(AuthenticationService.currentUserValue);

        const doFetch = async () => {
            if (currentUser?.name === undefined)
            {
                navigate("/login");
                return;
            }

            setProductInfos(await fetchProductInfos().catch(e => navigate("/login")));
            setLoading(false);
            getLoyaltyDiscount();
        }

        doFetch();
    }, [navigate, currentUser]);

    function renderData() {
        return (
            <div>
                <div className="newstitle">
                    <div className="well">
                        <h2>User: {currentUser.name}</h2>
                    </div>
                </div>
                <div className="well">
                    {
                        (loyaltyCouponActive) &&
                        <div>
                            <div className="text-center" style={{ backgroundColor: "#962f2f", padding: "20px", margin: "0" }}>
                                <h3 style={{ margin: "0px" }}>Get 15% loyalty discount on all products now</h3>
                            </div>
                            <br />
                        </div>
                    }
                    <h4>Licenses:</h4>
                    <p />
                    {
                        (productInfos.length > 0) ?
                            (
                                productInfos.map(n =>
                                    <div key={n.orderId}>
                                        <div className="simpletableLicense"><h4>{n.name}</h4>
                                            Product Page (downloads and manual): <Link to={`/products/${n.name}`.toLowerCase()}>{n.name}</Link>
                                            {
                                                (n.useRsa2048 && n.serialNew !== "") ?
                                                    (
                                                        <button className="btn btn-secondary" onClick=
                                                            {
                                                                (e) => saveAs(new Blob([n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt")
                                                            }>Download key for older releases</button>
                                                    )
                                                    :
                                                    (<div />)
                                            }
                                            {
                                                /*
                                                (n.serial.length > 2) ?
                                                    (
                                                        <div>Serial for releases before 2019: {n.serial}<br /></div>
                                                    )
                                                    :
                                                    (
                                                        <div />
                                                    )
                                                        */
                                            }

                                            <br />
                                            <button className="btn btn-secondary" onClick={
                                                (e) => saveAs(new Blob([n.useRsa2048 ? n.serial2048 : n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt")
                                            }>Download Actual Key</button>
                                            &nbsp;<OfflineRegistrationForm name={n.name} serialKey={n.serial2048} />
                                            &nbsp;
                                            {
                                                (shouldShowTransferButton(n.licenseActivationDate)) &&
                                                (
                                                    <button className="btn btn-secondary" onClick={(e) => transferLicenseEvent(e, n.orderId)}>Transfer License...</button>
                                                )
                                            }

                                            <br />
                                            <br />

                                            {
                                                (n.orderDevices !== undefined && n.orderDevices.length > 0) &&
                                                (
                                                    <div>
                                                        <h5>Registered computers (max 4):</h5>
                                                    </div>
                                                )
                                            }
                                            {
                                                (n.orderDevices !== undefined && n.orderDevices.length > 0) &&
                                                (
                                                    <div className="Container" >
                                                        <div className="table-responsive">
                                                            <Table className="simpletableDeviceOrder table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Device Name</th>
                                                                        <th scope="col">Device Id</th>
                                                                        <th style={{ textAlign: "right" }} scope="col">Activation Date</th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        n.orderDevices.map(d =>
                                                                            <tr key={d.id}>
                                                                                <td><b>{d.deviceName}</b></td>
                                                                                <td>{d.deviceIdHash}</td>
                                                                                <td style={{ textAlign: "right" }}>{d.creationDate}</td>
                                                                                <td style={{ textAlign: "right" }}>
                                                                                    {
                                                                                        (d.deviceName.includes("Offline")) ?
                                                                                            (
                                                                                                <button className="btn btn-secondary" onClick={
                                                                                                    (e) => saveAs(new Blob([d.deviceIdHashEncrypted], { type: "text/plain;charset=utf-8" }), n.name + "_OfflineKey.txt")
                                                                                                }>Download offline Key</button>
                                                                                            ) :
                                                                                            (
                                                                                                <div style={{ width: "90px" }} />
                                                                                            )
                                                                                    }
                                                                                </td>
                                                                                <td style={{ textAlign: "right" }}>
                                                                                    {
                                                                                        (shouldShowDeleteButton(Date.parse(d.creationDate))) ?
                                                                                            (
                                                                                                <button className="btn btn-secondary" onClick={(e) => deleteDeviceEvent(e, d.id)}>Delete</button>
                                                                                            ) :
                                                                                            (
                                                                                                <div style={{ width: "90px" }} />
                                                                                            )
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div>&nbsp;</div>

                                    </div>
                                ))
                            :
                            <div>No licenses found</div>
                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr className='hr' />
                    <h5>Change Password</h5>
                    <ChangePasswordForm />
                    <div />
                </div>
            </div>
        );
    }

    async function getLoyaltyDiscount() {
        await fetch(Config.apiUrl + '/fastspring/loyaltycoupon',
            {
                method: 'POST',
                headers: AuthHeader()
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data === undefined || data === "") {
                    return;
                }

                window.fastspring.builder.promo(data);
                setLoyaltyCouponActive(true);
            })
            .catch(e => { console.log(e) } /* NOP */);
    }

    function shouldShowDeleteButton(date) {
        var dateObj = new Date(date);
        var currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return dateObj < currentDate;
    }

    function shouldShowTransferButton(date) {
        var dateObj = new Date(date);
        var compareDate = new Date();
        compareDate.setDate(compareDate.getDate() - 30); // show transfer button after a few weeks
        return dateObj < compareDate;
    }

    /* // FIXME: maybe we should download the serial key in the future to safe bandwith when showing the license
    function downloadSerialEvent(event, orderId, productName) {
        return fetch(Config.apiUrl + '/userinfo/serial/' + orderId,
            {
                method: 'GET',
                type: 'text/plain;charset=utf-8',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(serialBlob => {
                var blob = new Blob([serialBlob.toString()], { type: "text/plain;charset=utf-8" });
                saveAs(blob, productName + "_SerialKey.txt")
            });
    }
            */

    async function deleteDeviceEvent(event, deviceOrderId) {
        const response = await fetch(Config.apiUrl + '/userinfo/orderdevice/' + deviceOrderId,
            {
                method: 'DELETE',
                headers: AuthHeader()
            });

        await HandleResponse(response);
        window.location.reload();
    }

    function transferLicenseEvent(event, orderId) {
        navigate('/transferlicense/' + orderId, { state: location });
    }


    let contents = <div className='loader' key="loader"></div>;
    if (!loading && currentUser?.name !== undefined) {
        contents = renderData();
    }

    return <div className="container">
        <div>
            {contents}
        </div>
    </div>;
}

//const UserAreaViewWithHooks = (props) => (<UserAreaView {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);
//export default UserAreaViewWithHooks;
